














































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import { RenderedBlock, Block } from '@/models'
import { BlockFragment, BlockUpdateFragment } from './fragments'
import Fields from '@/components/form/Fields.vue'
import PreviewParams from '@/components/tools/PreviewParams.vue'
import _isEqual from 'lodash/isEqual'
import _cloneDeep from 'lodash/cloneDeep'
import gql from 'graphql-tag'
import cleanData from '@/utils/gql/cleanData'
import { ApolloError } from 'apollo-client'
import { confirmDelete } from '@/components/dialogs'
import ComponentSelect from '@/components/fields/componentSelect/Field.vue'
import CollectionFieldSelect from '@/components/fields/collectionFieldSelect/Field.vue'
import SingleSelect from '@/components/fields/select/Field.vue'

@Component({
  components: {
    Loading,
    Fields,
    PreviewParams,
    ComponentSelect,
    CollectionFieldSelect,
    SingleSelect,
    MediumEditor: () => {
      return (
        // @ts-ignore
        import('medium-editor/dist/css/medium-editor.css')
          // @ts-ignore
          .then(() => import('medium-editor/dist/css/themes/default.css'))
          .then(() => import('vue2-medium-editor'))
          .then((m) => m.default)
      )
    }
  },
  apollo: {
    savedBlock: {
      query: gql`
        query getBlock($blockId: ID) {
          savedBlock: block(blockId: $blockId) {
            ...Block
          }
        }
        ${BlockFragment}
      `,
      variables() {
        return {
          blockId: this.componentId
        }
      }
    },
    preview: {
      query: gql`
        query getContent($blockId: ID, $preview: Boolean, $params: JSON) {
          preview: renderBlock(
            blockId: $blockId
            preview: $preview
            params: $params
          )
        }
      `,
      variables() {
        return {
          blockId: this.componentId,
          params: JSON.parse(this.previewParamsStr),
          preview: true
        }
      },
      error(e: ApolloError, instance: BlockEdit) {
        this.previewError = e.message
      }
    }
  }
})
export default class BlockEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId!: String
  @Prop({ type: String, required: true }) componentId!: String
  @Prop({ type: String, default: 'component' }) currentTab!: String

  mounted() {
    this.$emit('additionalTabs', {
      previewParams: 'Parámetros de Previsualización',
      preview: 'Previsualizar'
    })
  }

  preview: Readonly<RenderedBlock> | null = null

  saving = false
  previewParams: any[] = []
  previewError = ''

  savedBlock: Readonly<Block> | null = null
  block: Partial<Block> = {}

  mediumOptions = {
    placeholder: {
      text: 'Escribe el contenido acá. Selecciona el texto para cambiar el estilo.',
      hideOnClick: true
    },
    toolbar: {
      buttons: [
        'bold',
        'italic',
        'underline',
        'anchor',
        'h2',
        'h3',
        'quote',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull'
      ]
    }
  }

  @Watch('savedBlock')
  update(newData: Block) {
    this.$set(this, 'block', _cloneDeep(this.savedBlock))
    if (this.savedBlock && this.savedBlock.previewParams) {
      const pvParams = JSON.parse(this.savedBlock.previewParams)
      this.$set(
        this,
        'previewParams',
        Object.keys(pvParams).map((k: string) => ({
          name: k,
          type: typeof pvParams[k] as 'string' | 'number' | 'boolean',
          value: pvParams[k]
        }))
      )
    } else {
      this.previewParams = []
    }
  }

  @Watch('block.name')
  updateName(newName: string) {
    this.$emit('name', newName)
  }

  @Watch('previewParams')
  updateParams(newParams: any[]) {
    if (!this.block) return
    this.block.previewParams = this.previewParamsStr
  }

  // Clean data for dirty method
  getSavedBlockClean(): Partial<Block> | null {
    let saved: Partial<Block> | null = this.savedBlock
    if (saved?.previewParams === null) {
      saved.previewParams = '{}'
    }
    if (saved?.content === '') {
      saved.content = null
    }
    return saved
  }

  get dirty() {
    return !_isEqual(this.block, this.getSavedBlockClean())
  }

  get previewParamsStr() {
    const params: Record<string, any> = {}
    this.previewParams.forEach((param) => {
      params[param.name] = param.value
    })
    return JSON.stringify(params)
  }

  get previewContent() {
    return this.preview && this.preview.content
  }

  normalize() {
    if (!this.block.collectionId) {
      this.block.filterable = false
    }
    if (!this.block.filterable) {
      this.block.filtersIds = []
    }
    if (!this.block.filtersIds) {
      this.block.allowsNoFilter = true
      this.block.filterByDefault = ''
    }
    if (
      !this.block.allowsNoFilter &&
      this.block.filtersIds &&
      this.block.filtersIds.length <= 1
    ) {
      this.block.filterByDefault = this.block.filtersIds[0]
    }
  }

  async save() {
    if (!this.block || this.saving) return
    this.saving = true
    try {
      this.normalize()
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($blockId: ID, $block: UpdateBlockInput) {
            updateBlock(blockId: $blockId, block: $block) {
              ...Block
            }
          }
          ${BlockFragment}
        `,
        // Parameters
        variables: {
          blockId: this.componentId,
          block: cleanData(this.block, BlockUpdateFragment)
        }
      })

      await this.$apollo.queries.preview.refetch()

      this.$emit('save', result.data.updateBlock)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  async deleteItem() {
    if (
      !(await confirmDelete(
        '¿Seguro que quieres eliminar por completo este bloque de contenido?'
      ))
    )
      return
    if (!this.block || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($blockId: ID) {
            deleteBlock(blockId: $blockId)
          }
        `,
        // Parameters
        variables: {
          blockId: this.componentId
        }
      })
      this.$emit('delete', result.data.deleteBlock)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    }
  }

  handleMediumEdit(operation: any) {
    this.block.content = operation.api.origElements.innerHTML
  }

  dismiss() {
    this.$emit('dismiss')
  }
}
